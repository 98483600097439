import * as React from 'react';
import { graphql } from 'gatsby';

// utils
import { renderSwitch } from '../utils/Render';

export const query = graphql`
    {
        page: craftStyleguideStyleguideEntry {
            ...StyleguideFragment
        }
    }
`;

const StyleguidePage = ({ data: { page } }) => {
    const content = page.contentMatrix;

    return <div>{content.map((block, i) => renderSwitch(block, i))}</div>;
};

export default StyleguidePage;
